import useToast from '@fuse/hooks/useToast'
import {
	makeStyles, CircularProgress
} from '@material-ui/core'
import React, { useEffect, useState, useRef } from 'react'
import axios from '@fuse/utils/axios'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Autocomplete } from '@material-ui/lab'
import { Box, Stepper, Step, StepButton, Button, Icon, IconButton, TextField, Typography, Drawer, Paper, Grid, Switch } from '@mui/material'
import { Modal } from '@material-ui/core'
import FormControl from '@mui/material/FormControl';
import Editor from '@fuse/components/Editor';
import PropTypes from 'prop-types';
import AlphabeticalSorting from '@fuse/utils/alphabeticalSort'
import { useCurrentUser, useDebounce } from '@fuse/hooks'
import AddUserFromQuickAction from '../../../fuse-layouts/shared-components/modal/AddUserFromQuickAction'
import AddServiceStatusFromQuickAction from '../../../fuse-layouts/shared-components/modal/AddServiceStatusFromQuickAction'
import AddServiceSourceFromQuickAction from '../../../fuse-layouts/shared-components/modal/AddServiceSourceFromQuickAction'
import AddServiceCategoryFromQuickAction from '../../../fuse-layouts/shared-components/modal/AddServiceCategoryFromQuickAction'
import AddServicePriorityFromQuickAction from '../../../fuse-layouts/shared-components/modal/AddServicePriorityFromQuickAction'
import { useDispatch, useSelector } from 'react-redux';
import AlertModal from 'helper/alertModal'
import { usePermission } from '@fuse/hooks';
import { acceptFileType, ucFirst } from 'helper/common';
import { getAllAsset } from '../../../api/asset';
import { getAllCompany } from '../../../api/company';
import { getAllUser } from '../../../api/user';
import { closeFormViewerDialog, setFromData } from '../store/formViewDialogReducer'
import { SearchDropDownListPaginationComponent } from 'helper/SearchDropDownPaginationList'
import { downloadFile } from '@fuse/utils/fileUtils'

const useStyles = makeStyles(theme => (
	{
		uploadImageButton: {
			padding: '6px 16px',
			fontSize: '1.3rem',
			minWidth: '64px',
			boxSizing: 'border-box',
			transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
			fontFamily: '"Inter", sans-serif',
			fontWeight: 500,
			lineHeight: 1.75,
			borderRadius: '4px',
			textTransform: 'none',
			backgroundColor: '#6B2C57',
			color: '#ffffff',
			cursor: 'pointer'
		},
		loader: {
			display: 'flex',
			'& > span': {
				margin: '10px auto'
			},
			'& > * + *': {
				marginLeft: theme.spacing(2),
			},
		},
		subSection: {
			marginLeft: '20px',
			marginRight: '20px',
			marginBottom: '20px',
			padding: '10px',
			backgroundColor: '#F9FAFB',
			borderRadius: '4px'
		},
		endButtons: {
			borderRadius: '4px'
		},
		drawerWidth: {
			width: 520,
			borderTopLeftRadius: '8px',
			borderBottomLeftRadius: '8px',
			overflowX: 'hidden',
			overflowY: 'hidden'
		},
	}
))
function CustomTabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

CustomTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function AddServiceRequestFormDialog(props) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const toast = useToast(dispatch)
	const user = useCurrentUser();
	const [description, setDescription] = useState('')
	const [descriptionText, setDescriptionText] = useState('')
	const [currentUser, setCurrentUser] = useState(null)
	const [prioritiesMenu, setPriorityMenu] = useState([])
	const [sourcesMenu, setSourceMenu] = useState([])
	const [categoryMenu, setCategoryMenu] = useState([])
	const [statusMenu, setStatusMenu] = useState([])
	const [agentMenu, setAgentMenu] = useState([])
	const [uploadingFile, setUploadingFile] = useState(false)
	const [hideDetails, setHideDetails] = useState(false)
	const [triggerComponent, setTriggerComponent] = useState(new Date())

	const openState = useSelector((state) => state.ServiceRequestsApp.formViewDialog)
	const [loading, setLoading] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [openAddStatusModal, setOpenAddStatusModal] = useState(false);
	const [openAddSourceModal, setOpenAddSourceModal] = useState(false);
	const [openAddCategoryModal, setOpenAddCategoryModal] = useState(false);
	const [openAddPriorityModal, setOpenAddPriorityModal] = useState(false);
	const [openAddUserModal, setOpenAddUserModal] = useState(false);
	const [selectedFiles, setSelectedFiles] = useState([]);
	const { data: editFromState } = useSelector((state) => state.ServiceRequestsApp.formViewDialog);

	const types = [
		{
			id: 1,
			label: 'Incident'
		},
		{
			id: 1,
			label: 'Request'
		}
	]

	const [previewImageLink, setPreviewImageLink] = useState('')
	const [openImagePreview, setOpenImagePreview] = useState(false)
	const [openAlerModal, setOpenAlertModal] = useState(false)
	const [fileName, setfileName] = useState('')
	const [fileLocation, setFileLocation] = useState('')
	const [filePreviewLink, setFilePreviewLink] = useState('')
	const [openFilePreviewLink, setOpenFilePreviewLink] = useState(false)
	const [ checked, setChecked ] = useState(false)
	const steps = ['Contact Info', 'Asset Info', 'Ticket Info', 'Issue'];
    const [companyApiParams, setCompanyApiParams] = useState({});
    const [userApiParams, setUserApiParams] = useState({});
	const [activeStep, setActiveStep] = React.useState(0);
	const [completed, setCompleted] = React.useState({});
	const { hasPermission } = usePermission();
	const [client, setClient] = useState();
	const [asset, setAsset] = useState();
	const addServiceRequestPermission = hasPermission('add-service_request')
	const [usersSuggestionsForCC, setUsersSuggestionsForCC ] = useState([])
	const [ selectedUserForCC, setSelectedUserForCC ] = useState([]);

	const uploadInput = useRef();
	const handleStep = (step) => () => {
		setActiveStep(step);
	};

	const defaultValues = {
		client: null,
		user: null,
		asset: null,
		priority: null,
		source: null,
		type: null,
		agent: null,
		status: null,
		category: null,
		subjectLine: ''
	};

	const validationSchema = [
		// 1 Contact Info
		yup.object({
			client: yup.object().shape({
				id: yup.string().required('Client name is required.'),
				label: yup.string().required('Client name is required.')
			}).required('Client name is required!'),
			user: yup.object().shape({
				id: yup.string().required('User name is required.'),
				first_name: yup.string().notRequired(),
				last_name: yup.string().notRequired(),
				email: yup.string().notRequired(),
			}).required('User is required.')
		}),

		// 1 Asset Info
		yup.object({
			asset: yup.object().shape({
				id: yup.string().required('Asset name is required.'),
				label: yup.string().required('Asset name is required.')
			})
			.when({
				is: () => checked,
				then: (schema) => schema.required('Asset name is required!'),
				otherwise: (schema) => schema.nullable(),
			}),
		}),

		// 2 Ticket Info
		yup.object({
			priority: yup.object().shape({
				id: yup.string().notRequired(),
				label: yup.string().notRequired(),
				color: yup.object().shape({
					code: yup.string().required('Priority is required!'),
					name: yup.string().required('Priority is required!'),
				}).nullable()
			}).required('Priority is required!'),
			source: yup.object().shape({
				id: yup.string().required('Source is required!'),
				label: yup.string().required('Source is required!'),
			}).required('Source is required!'),
			type: yup.object().shape({
				id: yup.string().notRequired(),
				label: yup.string().required('Type is required!'),
			}).required('Type is required!'),
			agent: yup.object().shape({
				id: yup.string().required('Agent is required!'),
				label: yup.string().required('Agent is required!'),
			}).required('Agent is required!'),
			status: yup.object().shape({
				id: yup.string().notRequired(),
				label: yup.string().required('Status is required!'),
				color: yup.object().shape({
					code: yup.string().notRequired(),
					name: yup.string().notRequired(),
				}).nullable()
			}).required('Status is required!'),
			category: yup.object().shape({
				id: yup.string().required('Category is required!'),
				label: yup.string().required('Category is required!'),
			}).required('Category is required!'),
		}),

		yup.object({
			subjectLine: yup.string()
				.strict(true)
				.trim('Must not have trailing and leading spaces')
				.max(30, "Subject line must be at most 30 characters!")
				.required("Subject line is required"),
			description: yup.string()
				.strict(true)
				.trim('Must not have trailing and leading spaces')
				.required('Description is required'),
		})
	];

	const currentValidationSchema = validationSchema[activeStep];


	const methods = useForm({
		shouldUnregister: false,
		defaultValues,
		resolver: yupResolver(currentValidationSchema),
		mode: "onChange"
	});

	const { control, formState, handleSubmit, reset, setValue, clearErrors, getValues, trigger, register } = methods

	const { errors } = formState

	const fetchPrioritySuggestionsOnInputChange = useDebounce(value => {
		fetchPrioritySuggestions(value)
	}, 500);


	const fetchPrioritySuggestions = (search = '') => {
		setLoading(true)
		axios.get(`/service-request-priority/fetch-priority-suggestion?search=${search}`).then(response => {
			let priorities = AlphabeticalSorting(response.data.data, 'name')
			setLoading(false)
			const systemDefinedPriorities = [], rest = [];
			for( let item of priorities){
				let obj = { id: item.id, label: item.name, color: item.priority_color.code};
				if(item.is_system_generated) systemDefinedPriorities.push(obj)
				else rest.push(obj)
			}
			setPriorityMenu([...systemDefinedPriorities, ...rest]);
		}).catch(err => {
			console.log('136=>', err);
		})
	}


	const fetchSourceSuggestionsOnInputChange = useDebounce(value => {
		fetchSourceSuggestions(value)
	}, 500);


	const fetchSourceSuggestions = (search = '') => {
		setLoading(true)
		axios.get(`/service-request-source/fetch-source-suggestion?search=${search}`).then(response => {
			let sources = AlphabeticalSorting(response.data.data, 'source_name')
			setLoading(false)
			const systemDefinedSource = [], rest = [];
			for( let item of sources){
				let obj = { id: item.id, label: item.source_name };
				if(item.is_system_generated) systemDefinedSource.push(obj)
				else rest.push(obj)
			}
			setSourceMenu([...systemDefinedSource, ...rest]);
		}).catch(err => {
			console.log('136=>', err);
		})
	}

	const fetchAgentSuggestionsOnInputChange = useDebounce(value => {
		if (!value.length) {
			setAgentMenu([])
			return
		}
		fetchAgentSuggestions(value)
	}, 500);


	const fetchAgentSuggestions = (search = '') => {
		setLoading(true)
		axios.get(`/user/fetch-agent-suggetion?search=${search}`).then(response => {
			let agent = AlphabeticalSorting(response.data.data.users, 'name')
			setLoading(false)
			setAgentMenu(agent.map((item) => {
				return {
					id: item.id,
					label: item.name
				}
			}))
		}).catch(err => {
			console.log('136=>', err);
		})
	}

	const fetchCategorySuggestionsOnInputChange = useDebounce(value => {
		fetchCategorySuggestions(value)
	}, 500);


	const fetchCategorySuggestions = (search = '') => {
		setLoading(true)
		axios.get(`/service-request-category/fetch-category-suggestion?search=${search}`).then(response => {
			let categories = AlphabeticalSorting(response.data.data, 'category_name')
			setLoading(false)
			const systemDefinedCategories = [], rest = [];
			for( let item of categories){
				let obj = { id: item.id, label: item.category_name };
				if(item.is_system_generated) systemDefinedCategories.push(obj)
				else rest.push(obj)
			}
			setCategoryMenu([...systemDefinedCategories, ...rest]);
		}).catch(err => {
			console.log('136=>', err);
		})
	}

	const fetchStatusSuggestionsOnInputChange = useDebounce(value => {
		fetchStatusSuggestions(value)
	}, 500);


	const fetchStatusSuggestions = (search = '') => {
		setLoading(true)
		axios.get(`/service-request-status/fetch-status-suggestion?search=${search}`).then(response => {
			let assets = AlphabeticalSorting(response.data.data, 'status_name')
			setLoading(false)
			const systemDefinedStatus = [], rest = [];
			for( let item of assets){
				let obj = { id: item.id, label: item.status_name, color: item?.status_color?.code };
				if(item.is_system_generated) systemDefinedStatus.push(obj)
				else rest.push(obj)
			}
			setStatusMenu([...systemDefinedStatus, ...rest])
		}).catch(err => {
			console.log('136=>', err);
		})
	}

	const newStatusAddedEvent = (createdData) => {
		setOpenAddStatusModal(!openAddStatusModal)
		setValue("status", {
			id: createdData.id,
			label: createdData.status_name,
			color: createdData.status_color.code,
		})
		clearErrors("status")
		fetchStatusSuggestions()
	}

	const newSourceAddedEvent = (createdData) => {
		setOpenAddSourceModal(!openAddSourceModal)
		setValue("source", {
			id: createdData.id,
			label: createdData.source_name
		})
		clearErrors("source")
		fetchSourceSuggestions()
	}

	const newCategoryAddedEvent = (createdData) => {
		setOpenAddCategoryModal(!openAddCategoryModal)
		setValue("category", {
			id: createdData.id,
			label: createdData.category_name
		})
		clearErrors("category")
		fetchCategorySuggestions()
	}


	const newPriorityAddedEvent = (createdData) => {
		setOpenAddPriorityModal(!openAddPriorityModal)
		setValue("priority", {
			id: createdData.id,
			label: createdData.name,
			color: createdData.priority_color.code
		})
		clearErrors("priority")
		fetchPrioritySuggestions()
	}

	function handleHasSerialNumberCheckBox( event ){
		setChecked(event.target.checked)
		if(!event.target.checked){
			setAsset(null)
			setValue('asset', null)
			clearErrors('asset')
		}
	}

	const newUserAddedEvent = (createdData) => {
		setOpenAddUserModal(!openAddUserModal)
		setValue('user', {
			id: createdData?.id,
			label: `${createdData?.first_name} ${createdData?.last_name} (${createdData.email})`,
			title: createdData?.title,
			email: createdData?.email,
			phone: createdData?.phone,
			key: `${createdData?.id}|${createdData?.first_name} ${createdData?.last_name} (${createdData.email})`,
		})
		setCurrentUser({
			id: createdData.id,
			first_name: createdData.first_name,
			last_name: createdData.last_name,
			phone: createdData.phone,
			email: createdData.email,
			title: createdData.title,
		})
	}

	function fetchServiceRequestDetails(serviceRequestId) {
		if (!Boolean(serviceRequestId)) return
		setLoading(true)
		axios.get(`/service/get-service-request-details/${serviceRequestId}?archived=${false}`)
			.then((res) => {
				const response = res.data.data.serviceTicketDetails
				setValue('client', {
					id: response?.user_company?.id,
					label: response?.user_company?.company_name,
					key: `${response.user_company?.id}|${response.user_company?.company_name}`,
				})
				setValue('user', {
					id: response?.company_user?.id,
					label: `${response?.company_user?.first_name} ${response?.company_user?.last_name}(${response?.company_user.email})`,
					first_name: response?.company_user?.first_name,
					last_name: response?.company_user?.last_name,
					phone: response?.company_user?.phone,
					email: response?.company_user?.email,
					key: `${response?.company_user?.id}|${response?.company_user?.first_name} ${response?.company_user?.last_name}(${response?.company_user.email})`,
				})
				setCurrentUser({
					id: response?.company_user?.id,
					first_name: response?.company_user?.first_name,
					last_name: response?.company_user?.last_name,
					phone: response?.company_user?.phone,
					email: response?.company_user?.email,
					title: response?.company_user?.title,
				})
				response?.asset ? (setValue('asset', {
					id: response?.asset?.id,
					label: response.asset.serial_number,
					name: response?.asset.asset_name,
					modelName: response.asset.asset_model.model_name ?? null,
					modelNumber: response.asset.asset_model.model_no ?? null,
					connection_status: response.asset?.connection_status ?? null,
					shipping_location_details: {
						id: response?.asset?.shipping.id,
						location_name: response?.asset?.shipping?.location_name,
						address1: response?.asset?.shipping?.address1,
						city: response?.asset?.shipping?.city,
						state: response?.asset?.shipping?.state,
						country: response?.asset?.shipping?.country,
					},
					key: `${response.asset.id}|${response.asset.serial_number}`,
				})) : setValue('asset', null)
				setValue('priority', response.service_request_priority?.id ? {
					id: response.service_request_priority?.id,
					label: response.service_request_priority?.name,
					color: response.service_request_priority?.priority_color?.code,
				} : null)
				setValue('source', response?.service_request_source?.id ? {
					id: response?.service_request_source?.id,
					label: response?.service_request_source?.source_name
				} : null)
				setValue('type', {
					id: response?.ticket_type === 'request' ? 2 : 1,
					label: ucFirst(response?.ticket_type)
				})
				setValue('agent', response?.agent?.id ? {
					id: response.agent.id,
					label: response.agent.name,
				} : null)
				setValue('status', response?.service_request_status?.id ? {
					id: response?.service_request_status?.id,
					label: response?.service_request_status?.status_name,
					color: response.service_request_status?.status_color?.code,
				} : null)
				setValue('category', response?.service_request_categories?.id ? {
					id: response?.service_request_categories?.id,
					label: response?.service_request_categories?.category_name
				} : null)
				setValue('subjectLine', response.title)
				setValue('description', response.description)
				setDescription(response.description)
				// setfileName(response.req_file)
				// setFileLocation(response.req_file_location)
				// setFilePreviewLink(response.image_link || '')
				response?.asset ? (setAsset({
					id: response?.asset?.id,
					label: response.asset.serial_number,
					name: response?.asset.asset_name,
					modelName: response.asset.asset_model.model_name ?? null,
					modelNumber: response.asset.asset_model.model_no ?? null,
					connection_status: response.asset?.connection_status ?? null,
					shipping_location_details: {
						id: response?.asset?.shipping.id,
						location_name: response?.asset?.shipping?.location_name,
						address1: response?.asset?.shipping?.address1,
						city: response?.asset?.shipping?.city,
						state: response?.asset?.shipping?.state,
						country: response?.asset?.shipping?.country,
					},
					key: `${response.asset.id}|${response.asset.serial_number}`,
				})) : (setAsset())
				setClient({
					id: response?.user_company?.id,
					label: response?.user_company?.company_name
				})
				setSelectedFiles(response?.service_request_files)
				setLoading(false)
				setChecked(Boolean(response.asset))
				setSelectedUserForCC(response.cc_users ?? [])
			}).catch((err) => {
				console.log('err', err);
				setLoading(true)
			})
	}


	const fetchUserSuggestionsForAdditionalCC = () => {
		if(!getValues('client')) return
		axios.get(`/assets/fetch-users-suggestion?client_id=${getValues('client').id}`).then(response => {
			const { users } = response.data.data;
			setUsersSuggestionsForCC((users.filter(i => i.email !== user.data.email)).map(i =>i.email))
		}).catch(err => {
			console.log('164=>',err);
		})
	}

	useEffect(()=>{
		// fetchUserSuggestionsForAdditionalCC()
	},[getValues('client')]);

	useEffect(() => {
		fetchPrioritySuggestions()
		fetchSourceSuggestions()
		fetchAgentSuggestions()
		fetchCategorySuggestions()
		fetchStatusSuggestions()
	}, [])

	useEffect(() => {
		fetchServiceRequestDetails(editFromState?.id)
	}, [editFromState])

	const handleOpenCloseAddStatus = () => {
		setOpenAddStatusModal(!openAddStatusModal)
	}

	const handleOpenCloseAddUser = () => {
		setOpenAddUserModal(!openAddUserModal);
	}

	const handleOpenCloseAddSource = () => {
		setOpenAddSourceModal(!openAddSourceModal)
	}

	const handleOpenCloseAddCategory = () => {
		setOpenAddCategoryModal(!openAddCategoryModal)
	}

	const handleOpenCloseAddPriority = () => {
		setOpenAddPriorityModal(!openAddPriorityModal)
	}

	const handleNext = async () => {
		await trigger();
		switch (activeStep) {
			// Required Details
			case 0:
				if (Object.keys(errors).length !== 0) {
					return;
				}
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
				break;
			case 1:
				if (Object.keys(errors).length !== 0) {
					return;
				}

				setActiveStep((prevActiveStep) => prevActiveStep + 1);
				break;
			case 2:
				if (Object.keys(errors).length !== 0) {
					return;
				}

				setActiveStep((prevActiveStep) => prevActiveStep + 1);
				break;
			case 3:
				if (Object.keys(errors).length !== 0) {
					return;
				}

				setActiveStep((prevActiveStep) => prevActiveStep + 1);
				break;
			default:
				break;
		}
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const onSubmit = async(data) => {
		let payload = {
			client_id: data?.client.id,
			user_id: data?.user?.id,
			asset_id: data?.asset?.id,
			status_id: data?.status?.id ?? null,
			category_id: data?.category?.id ?? null,
			source_id: data?.source?.id ?? null,
			priority: data?.priority?.id ?? null,
			ticket_type: data?.type?.label.toLowerCase(),
			agent_id: data?.agent?.id ?? null,
			title: data.subjectLine,
			description: description,
			additional_cc_users: selectedUserForCC
			// req_file: selectedFiles ?? null,
			// req_file_location: fileLocation ?? null,
			// image_link: previewImageLink ?? null,
			// location_id: getValues('location')?.id,
		}
		setIsLoading(true)
		if (editFromState) {
			payload.service_request_id = editFromState.id
			axios.post(`/service/request/edit`, payload, {
			}).then(results => {
				toast.success(results.data.message)
				reset(defaultValues);
				props.getServiceRequest()
				onCancel()
			}).catch(err => {
				console.log(err)
				toast.error(err.response.data.message);
			}).finally(() => {
				setTimeout(() => {
					// setLoading(false)
				}, 500)
			});
		} else {
			let result
			await axios.post(`/service/request/submit`, payload, {
			}).then(results => {
				result = results.data
				if (selectedFiles.length === 0){
					toast.success(result.message)
					reset(defaultValues);
					props.getServiceRequest()
					onCancel()
				}
			}).catch(err => {
				console.log(err)
				toast.error(err.response.data.message);
			}).finally(() => {
				setIsLoading(false)
			});

			if (selectedFiles.length > 0 && result.statusCode === 200) {
				setUploadingFile(true)
				const formData = new FormData();
				for (var i = 0; i < selectedFiles.length; i++) {
				  formData.append('files', selectedFiles[i]);
				}
				formData.append('service_request_id', result.data.id);
				formData.append('user_companies_id', data?.client.id);
			await axios.post('/service/service-request-file-upload', formData).then(response => {
				toast.success(result.message)
				reset(defaultValues);
				props.getServiceRequest()
				onCancel()
			}).catch(err => {
				console.log('136=>', err);
				toast.error('Something went wrong!')
			}).finally(() => {
				setUploadingFile(false)
			})
		}
		}
	}


	const handleAlertModal = (_, reason) => {
		if (reason === 'backdropClick') {
			return;
		}

		setOpenAlertModal(!openAlerModal)

	}
	const handleCloseAlertModal = () => {
		setOpenAlertModal(false)
		onCancel()
	}

	const onCancel = () => {
		dispatch(closeFormViewerDialog())
		dispatch(setFromData())
		setfileName('')
		setFileLocation('')
		setFilePreviewLink('')
		setCurrentUser()
		setActiveStep(0)
		setDescriptionText('')
		setDescription('')
	}

	useEffect(() => {
		
		const handleBeforeUnload = (event) => {	  
		  getValues('client') && event.preventDefault();
		};
		window.addEventListener('beforeunload', handleBeforeUnload);
		return () => {
		  window.removeEventListener('beforeunload', handleBeforeUnload);
		};

	}, []);

	// const onChangeImageUpload = (event) => {
	// 	if (selectedFiles.length >= 10) {
	// 		return toast.error("You cannot add more than 10 file.");
	// 	}
	// 	setUploadingFile(true)
	// 	const selectedFile = event.target.files[0]
	// 	if (!selectedFile) return
	// 	if (selectedFile.size > 2000000) {
	// 		toast.error("File size cannot exceed 2Mb!");
	// 		setUploadingFile(true)
	// 		return;
	// 	} else {
	// 		let formData = new FormData()
	// 		formData.append("file", selectedFile)

	// 		axios.post('/service/service-request-file-upload', formData).then(response => {
	// 			if (response.status === 201) {
	// 				toast.error(response.data.message)
	// 			}
	// 			else {
	// 				setSelectedFiles((prev) => [...prev, response.data.data]);
	// 				setfileName(selectedFile.name)
	// 				setFileLocation(response.data.data.imageLocation)
	// 				setFilePreviewLink(response.data.data.imageLink)
	// 			}
	// 		}).catch(err => {
	// 			console.log('136=>', err);
	// 			toast.error('Something went wrong!')
	// 		}).finally(() => {
	// 			setUploadingFile(false)
	// 		})
	// 	}
	// }

	const handelChange = (event) => {
		if (selectedFiles.length >= 10) {
			return toast.error("You cannot add more than 10 file.");
		}
		const fileExtension = event.target.files[0] ? `.${event.target.files[0].name.split('.').pop().toLowerCase()}` : '';
		if (event.target.files[0] && !acceptFileType.includes(fileExtension)) {
		  return toast.error(`${fileExtension} files are not allowed!`)
		}
		if (event.target.files[0].size > 2000000) {
			return toast.error("File size cannot exceed 2Mb!");
		}
		if (event.target.files[0]) {
		  setSelectedFiles((prev) => [...prev, event.target.files[0]])
		}
	}


	const removeFile = (file) => {
		setSelectedFiles(selectedFiles.filter(function (item) {
			return item.name !== file.name
		}))
	}
	
	return (
		<div>
			<Drawer anchor='right' open={addServiceRequestPermission ? openState.open : false} classes={{ paper: classes.drawerWidth }} className='cstm_drawer_holder'>
				<div className='heading_holder'>
					<h4 className='heading'>{editFromState ? (editFromState?.cloneItem ? 'Clone ' : 'Update ') : 'Add '} Service Ticket</h4>
					<IconButton onClick={handleAlertModal} style={{ padding: '0px' }}>
						<Icon style={{ fontSize: '24px', color: '#1d2939' }}>close</Icon>
					</IconButton>
				</div>

				<div className='progressbar_holder'>
					<div style={{ width: activeStep === 0 ? '25%' : activeStep === 1 ? '50%' : activeStep === 2 ? '75%' : activeStep === 3 ? '100%' : '0%' }} className='progress'>
						&nbsp;
					</div>
				</div>

				<div className='cstm_wizard_holder'>
					<Box sx={{ width: '100%' }} className='box_holder'>
						<Stepper activeStep={activeStep} style={{marginBottom: '28px'}} className='stepper_holder'>
							{steps.map((label, index) => (
								<Step className='each_step_holder' key={label} completed={completed[index]}>
									<StepButton color="inherit" onClick={handleStep(index)}>
										{label}
									</StepButton>
								</Step>
							))}
						</Stepper>
						<div className='main_content_holder mt-5'>
							<>
								<div className='content_holder'>
									{activeStep === 0 &&
										<div className='box_holder'>
											<Box>
												<Grid container spacing={2}>
													<Grid item xs={12}>
														<Box>
															<Typography className='status_text'>
																Client Name
																<span className='required_span'>*</span>
															</Typography>
															<FormControl fullWidth className='assets_listSelect'>
																<Controller
																	name="client"
																	className="mb-16"
																	control={control}
																	render={({ field: { ref, ...field }, fieldState: { error } }) => (
																		<>
																		<SearchDropDownListPaginationComponent
																			label="Select Client"
																			module="Company"
																			height='50px'
																			searchEnabled={true}
																			disabled={editFromState}
																			listCall={getAllCompany}
																			emitItem={(value) => {
																				field.onChange(value)
																					setClient(value)
																					setCurrentUser(null)
																					setValue('user', null)
																					setValue('asset', null)
																					setValue('client', value)
																					setAsset()
																					setTriggerComponent(+new Date())
																					if(value){
																						setUserApiParams({
																							client_id:value.id
																						})
																					}

																			}}
																			apiParams={companyApiParams}
																			defaultValue={getValues().client}
																			className='main_autocomplete'
																		/>
																		{errors?.client && <span className='required_span  mt-2' 
																			style={ {
																				fontSize: '12px',
																				color: '#FF0000',
																			}}
																		>Client name is required!</span>}																		
																		</>
																	)}
																/>
															</FormControl>
														</Box>
													</Grid>
													<Grid item xs={12}>
														<Box>
															<Typography className='status_text'>User<span className='required_span'>*</span></Typography>
															<FormControl fullWidth className='assets_listSelect' key={triggerComponent}>
																<Controller
																	name='user'
																	control={control}
																	render={({ field: { ref, ...field }, fieldState: { error } }) => (
																		<>
																		<SearchDropDownListPaginationComponent
																			label="Select User"
																			module="User"
																			height='50px'
																			searchEnabled={true}
																			disabled={!client || editFromState}
																			listCall={getAllUser}
																			emitItem={(value) => {
																				field.onChange(value)
																				if(value){
																				setCurrentUser({
																					id: value.id,
																					first_name: value.first_name,
																					last_name: value.last_name,
																					phone: value.phone,
																					email: value.email,
																					title: value.title,
																				})
																			}else{
																				setCurrentUser()
																			}
																			}}
																			defaultValue={getValues().user}
																			className='main_autocomplete'
																			PaperComponent={({ children }) => (
																				<Paper className='autocomplete_holder extra_styling'>{children}
																					<div className='p-16'>
																						<Button className='secondary_btn w-full' onMouseDown={() => handleOpenCloseAddUser()}>
																							<i className='ti ti-plus' />
																							<span>Add User</span>
																						</Button>
																					</div>
																				</Paper>
																			)}
																			apiParams={userApiParams}

																		/>
																		{errors?.user && <span className='required_span  mt-2' 
																			style={ {
																				fontSize: '12px',
																				color: '#FF0000',
																			}}
																		>User name is required!</span>}																	
																		</>
																	)}
																/>
															</FormControl>
														</Box>
													</Grid>
													{/* <Grid item xs={12}>
														<Box>
															<Typography className='status_text'>Additional cc</Typography>
																<Autocomplete
																	style={{ width: '100%' }}
																	options={usersSuggestionsForCC}
																	disabled={!client}
																	value={selectedUserForCC}
																	onChange={(event, value) => {
																		if(value.length > 10){
																			toast.error("Cannot add more than 10 users")
																			return
																		}
																		setSelectedUserForCC(value)
																	}}
																	multiple																				
																	getOptionLabel={option => option ?? ''}
																	renderInput={params => {
																			return (
																				<div ref={params.InputProps.ref}>
																					<TextField
																						required
																						{...params}
																						variant='outlined'
																						fullWidth
																						FormHelperTextProps={{
																							style: {
																								margin: 0,
																								backgroundColor: '#f4f4f4',
																								width: '100%',
																								paddingTop: '2px',
																								fontSize: '10px'
																							}
																						}}
																						style={{ width: '100%', backgroundColor: '#fff' }}
																					/>
																				</div>
																			)
																	}}
																/> 																
														</Box>
													</Grid> */}
													{currentUser &&
														<>
															<Grid item xs={12}>
																<Box>
																	<Typography className='status_text'>Phone Number</Typography>
																	<TextField
																		style={{ background: '#FCFCFD' }}
																		className='serial_numberField'
																		variant='outlined'
																		value={currentUser?.phone ?? ''}
																		fullWidth
																		disabled
																		FormHelperTextProps={{
																			style: {
																				margin: 0,
																				backgroundColor: '#FCFCFD',
																				width: '100%',
																				paddingTop: '2px',
																				border: '1px solid #F2F4F7',
																				fonstSize: '10px'
																			}
																		}}
																	/>
																</Box>
															</Grid>
															<Grid item xs={12}>
																<Box>
																	<Typography className='status_text'>Email Address</Typography>
																	<TextField
																		style={{ background: '#FCFCFD' }}
																		className='serial_numberField'
																		variant='outlined'
																		value={currentUser?.email ?? ''}
																		disabled
																		fullWidth
																		FormHelperTextProps={{
																			style: {
																				margin: 0,
																				backgroundColor: '#FCFCFD',
																				width: '100%',
																				paddingTop: '2px',
																				border: '1px solid #F2F4F7',
																				fonstSize: '10px'
																			}
																		}}
																	/>
																</Box>
															</Grid>
															<Grid item xs={12}>
																<Box>
																	<Typography className='status_text'>Job Title</Typography>
																	<TextField
																		style={{ background: '#FCFCFD' }}
																		className='serial_numberField'
																		variant='outlined'
																		value={currentUser?.title ?? ''}
																		disabled
																		fullWidth
																		FormHelperTextProps={{
																			style: {
																				margin: 0,
																				backgroundColor: '#FCFCFD',
																				width: '100%',
																				paddingTop: '2px',
																				border: '1px solid #F2F4F7',
																				fonstSize: '10px'
																			}
																		}}
																	/>
																</Box>
															</Grid>
														</>
													}
												</Grid>
											</Box>
										</div>
									}
									{activeStep === 1 &&
										<>
											<Box>
												<Grid container spacing={2}>													
													<Grid item xs={12}>
														<Box>
															<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
																<Typography className='status_text'>
																	I have a Serial Number
																</Typography>
																<Switch
																	color="warning"
																	checked={checked}
																	onChange={handleHasSerialNumberCheckBox}
																/>
															</div>
															{ checked && (
																<>
																	<Typography className='status_text'>Serial Number <span className='required_span'>*</span></Typography>
																	<FormControl fullWidth className='assets_listSelect'>
																		<Controller
																			name="asset"
																			className="mb-16"
																			control={control}
																			render={({ field: { ref, ...field }, fieldState: { error } }) => (
																				<>
																				<SearchDropDownListPaginationComponent
																					label="Type your serial number here to help us find your asset"
																					module="Asset"
																					searchEnabled={true}
																					listCall={getAllAsset}
																					disabled={!client}
																					emitItem={(value) => {
																						field.onChange(value ?? null)
																						setAsset(value)
																					}}
																					defaultValue={getValues().asset}
																					className='main_autocomplete'
																					apiParams={{
																						client_id: getValues()?.client?.id
																					}}

																				/>
																				{errors?.asset && <span className='required_span  mt-2' 
																					style={ {
																						fontSize: '12px',
																						color: '#FF0000',
																					}}
																				>Serial number is required!</span>}
																				</>
																			)}
																		/>
																	</FormControl>
																</>
															)}
														</Box>
													</Grid>

													{asset &&
														<>
															<Grid item xs={12}>
																<Box>
																	<div style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center' }}>
																		<Typography className='status_text'>Asset Name</Typography>
																		<Button className='link_type_btn' onClick={() => setHideDetails(!hideDetails)}>
                                                                            <span> {!hideDetails ? 'Hide' : 'Show'} Details</span>
                                                                        </Button>
																	</div>
																	<div 
																		style={{
																			margin: 0,
																			padding: '8px 16px',
																			borderRadius: '4px',
																			backgroundColor: '#FCFCFD',
																			width: '100%',
																			border: '1px solid #12B76A',
    																		fontFamily: 'Inter, sans-serif',
																			fonstSize: '14px'
																		}}
																	>
																		{asset?.name ?? ''}
																	</div>
																	{/* <TextField
																		style={{ background: '#FCFCFD' }}
																		className='serial_numberField'
																		variant='outlined'
																		value={getValues()?.asset?.name ?? ''}
																		fullWidth
																		disabled
																		FormHelperTextProps={{
																			style: {
																				margin: 0,
																				backgroundColor: '#FCFCFD',
																				width: '100%',
																				paddingTop: '2px',
																				border: '1px solid #12B76A',
																				fonstSize: '10px'
																			}
																		}}
																	/> */}
																</Box>
															</Grid>
															{!hideDetails &&
															<>
																<Grid item xs={6}>
																	<Box>
																		<Typography style={{color:"#667085"}} className='status_text'>Model Name</Typography>
																		<Typography style={{fontFamily: 'Inter, sans-serif',fontWeight:500,fontSize:'14px',color:'#1D2939'}} variant="subtitle1">
																			{asset?.modelName ?? 'N/A'}
																			<span className='orange_badge_holder ml-3'>{asset?.connection_status ?? 'N/A'}</span>
																		</Typography>
																	</Box>
																</Grid>
																<Grid item xs={6}>
																	<Box>
																		<Typography style={{color:"#667085"}} className='status_text'>Model Number</Typography>
																		<Typography style={{fontFamily: 'Inter, sans-serif',fontWeight:500,fontSize:'14px',color:'#1D2939'}} variant="subtitle1">{asset?.modelNumber ?? 'N/A'}</Typography>
																	</Box>
																</Grid>
																<Grid item xs={12}>
																	<Box>
																		<Typography style={{color:"#667085"}} className='status_text'>Location</Typography>
																		<Typography style={{fontFamily: 'Inter, sans-serif',fontWeight:500,fontSize:'14px',color:'#1D2939'}} variant="subtitle1">{`${asset?.shipping_location_details?.location_name}-${asset?.shipping_location_details?.address1}, ${asset?.shipping_location_details?.city}, ${asset?.shipping_location_details?.state}, ${asset?.shipping_location_details?.country}`?? 'N/A'}</Typography>
																	</Box>
																</Grid>
															</>
															}
														</>
													}
												</Grid>
											</Box>
										</>
									}
									{activeStep === 2 &&
										<>
											<Box>
												<Grid container spacing={2}>
													<Grid item xs={6}>
														<Box>
															<Typography className='status_text'>Priority <span className='required_span'>*</span></Typography>
															<FormControl fullWidth className='assets_listSelect'>
																<Controller
																	name="priority"
																	className="mb-16"
																	control={control}
																	render={({ field: { ref, ...field }, fieldState: { error } }) => (
																		<>
																			<Autocomplete
																				{...field}
																				style={{ width: '100%' }}
																				className='main_autocomplete'
																				options={prioritiesMenu}
																				// disablePortal
																				PaperComponent={({ children }) => (
																					<Paper className='autocomplete_holder extra_styling'>{children}
																						<div className='p-16'>
																							<Button className='secondary_btn w-full' onMouseDown={() => handleOpenCloseAddPriority()}>
																								<i className='ti ti-plus' />
																								<span>Add Priority</span>
																							</Button>
																						</div>
																					</Paper>
																				)}
																				onChange={(event, value) => field.onChange(value)}
																				getOptionLabel={option => option.label ?? ''}
																				renderOption={(props, option) => (
																					<>
																						<span className="cstm_black_border_badge_holder" style={{ borderColor: props.color }}>
																							<span className="dot_holder" style={{ backgroundColor: props.color }}>&nbsp;</span>
																							{props.label ?? ''}
																						</span>
																					</>
																				)}
																				renderInput={(params) => {
																					return (
																						<div ref={params.InputProps.ref}>
																							<TextField
																								name="priority"
																								style={{ background: 'white' }}
																								{...params}
																								// label="Status"
																								placeholder='Select'
																								required
																								onChange={(event) => {
																									if (event && event.target) {
																										fetchPrioritySuggestionsOnInputChange(event.target.value)
																									}
																								}}
																								defaultValue={defaultValues.priority}
																								error={!!errors.priority?.id}
																								helperText={errors?.priority?.message && 'Priority is required'}
																								FormHelperTextProps={{
																									style: {
																										margin: 0,
																										backgroundColor: '#f4f4f4',
																										width: '100%',
																										paddingTop: '2px',
																										fontSize: '10px',
																										color: '#FF0000'
																									}
																								}}
																								variant="outlined"
																								fullWidth
																							/>
																						</div>
																					)
																				}}
																			/>
																		</>
																	)}
																/>
															</FormControl>
														</Box>
													</Grid>
													<Grid item xs={6}>
														<Box>
															<Typography className='status_text'>Source <span className='required_span'>*</span></Typography>
															<FormControl fullWidth className='assets_listSelect'>
																<Controller
																	name="source"
																	className="mb-16"
																	control={control}
																	render={({ field: { ref, ...field }, fieldState: { error } }) => (
																		<>
																			<Autocomplete
																				{...field}
																				style={{ width: '100%' }}
																				className='main_autocomplete'
																				options={sourcesMenu}
																				// disablePortal
																				PaperComponent={({ children }) => (
																					<Paper className='autocomplete_holder extra_styling'>{children}
																						<div className='p-16'>
																							<Button className='secondary_btn w-full' onMouseDown={() => handleOpenCloseAddSource()}>
																								<i className='ti ti-plus' />
																								<span>Add Source</span>
																							</Button>
																						</div>
																					</Paper>
																				)}
																				onChange={(event, value) => field.onChange(value)}
																				getOptionLabel={option => option.label ?? ''}
																				renderInput={(params) => {
																					return (
																						<div ref={params.InputProps.ref}>
																							<TextField
																								name="source"
																								style={{ background: 'white' }}
																								{...params}
																								// label="Status"
																								placeholder='Select'
																								required
																								onChange={(event) => {
																									if (event && event.target) {
																										fetchSourceSuggestionsOnInputChange(event.target.value)
																									}
																								}}
																								defaultValue={defaultValues.source}
																								error={!!errors.source?.id}
																								helperText={errors?.source?.message && 'Source is required'}
																								FormHelperTextProps={{
																									style: {
																										margin: 0,
																										backgroundColor: '#f4f4f4',
																										width: '100%',
																										paddingTop: '2px',
																										fontSize: '10px',
																										color: '#FF0000'
																									}
																								}}
																								variant="outlined"
																								fullWidth
																							/>
																						</div>
																					)
																				}}
																			/>
																		</>
																	)}
																/>
															</FormControl>
														</Box>
													</Grid>
													<Grid item xs={6}>
														<Box>
															<Typography className='status_text'>Type <span className='required_span'>*</span></Typography>
															<FormControl fullWidth className='assets_listSelect'>
																<Controller
																	name="type"
																	className="mb-16"
																	control={control}
																	render={({ field: { ref, ...field }, fieldState: { error } }) => (
																		<>
																			<Autocomplete
																				{...field}
																				style={{ width: '100%' }}
																				className='main_autocomplete'
																				options={types}
																				// disablePortal
																				///disabled={editFromState}
																				onChange={(event, value) => field.onChange(value)}
																				getOptionLabel={option => option.label ?? ''}
																				renderInput={(params) => {
																					return (
																						<div ref={params.InputProps.ref}>
																							<TextField
																								name="type"
																								placeholder='Select'
																								style={{ background: 'white' }}
																								{...params}
																								required
																								defaultValue={defaultValues.type}
																								error={!!errors.type?.id}
																								helperText={errors?.type?.message && 'Type is required'}
																								FormHelperTextProps={{
																									style: {
																										margin: 0,
																										backgroundColor: '#f4f4f4',
																										width: '100%',
																										paddingTop: '2px',
																										fontSize: '10px',
																										color: '#FF0000'
																									}
																								}}
																								variant="outlined"
																								fullWidth
																							/>
																						</div>
																					)
																				}}
																			/>
																		</>
																	)}
																/>
															</FormControl>
														</Box>
													</Grid>
													<Grid item xs={6}>
														<Box>
															<Typography className='status_text'>Agent Name <span className='required_span'>*</span></Typography>
															<FormControl fullWidth className='assets_listSelect'>
																<Controller
																	name="agent"
																	className="mb-16"
																	control={control}
																	render={({ field: { ref, ...field }, fieldState: { error } }) => (
																		<>
																			<Autocomplete
																				{...field}
																				style={{ width: '100%' }}
																				className='main_autocomplete'
																				options={agentMenu}
																				// disablePortal
																				onChange={(event, value) => field.onChange(value)}
																				getOptionLabel={option => option.label ?? ''}
																				renderInput={(params) => {
																					return (
																						<div ref={params.InputProps.ref}>
																							<TextField
																								name="agent"
																								style={{ background: 'white' }}
																								{...params}
																								placeholder='Select'
																								// label="Status"
																								required
																								onChange={(event) => {
																									if (event && event.target) {
																										fetchAgentSuggestionsOnInputChange(event.target.value)
																									}
																								}}
																								defaultValue={defaultValues.agent}
																								error={!!errors.agent?.id}
																								helperText={errors?.agent?.message && 'Agent is required'}
																								FormHelperTextProps={{
																									style: {
																										margin: 0,
																										backgroundColor: '#f4f4f4',
																										width: '100%',
																										paddingTop: '2px',
																										fontSize: '10px',
																										color: '#FF0000'
																									}
																								}}
																								variant="outlined"
																								fullWidth
																							/>
																						</div>
																					)
																				}}
																			/>
																		</>
																	)}
																/>
															</FormControl>
														</Box>
													</Grid>
													<Grid item xs={6}>
														<Box>
															<Typography className='status_text'>Status <span className='required_span'>*</span></Typography>
															<FormControl fullWidth className='assets_listSelect'>
																<Controller
																	name="status"
																	className="mb-16"
																	control={control}
																	render={({ field: { ref, ...field }, fieldState: { error } }) => (
																		<>
																			<Autocomplete
																				{...field}
																				style={{ width: '100%' }}
																				className='main_autocomplete'
																				options={statusMenu}
																				// disablePortal
																				PaperComponent={({ children }) => (
																					<Paper className='autocomplete_holder extra_styling'>{children}
																						<div className='p-16'>
																							<Button className='secondary_btn w-full' onMouseDown={() => handleOpenCloseAddStatus()}>
																								<i className='ti ti-plus' />
																								<span>Add Status</span>
																							</Button>
																						</div>
																					</Paper>
																				)}
																				onChange={(event, value) => field.onChange(value)}
																				getOptionLabel={option => option.label ?? ''}
																				renderOption={(props, option) => (
																					<>
																						<span className="cstm_black_border_badge_holder" style={{ borderColor: props.color }}>
																							<span className="dot_holder" style={{ backgroundColor: props.color }}>&nbsp;</span>
																							{props.label ?? ''}
																						</span>
																					</>
																				)}
																				renderInput={(params) => {
																					return (
																						<div ref={params.InputProps.ref}>
																							<TextField
																								name="status"
																								style={{ background: 'white' }}
																								{...params}
																								// label="Status"
																								placeholder='Select'
																								required
																								onChange={(event) => {
																									if (event && event.target) {
																										fetchStatusSuggestionsOnInputChange(event.target.value)
																									}
																								}}
																								defaultValue={defaultValues.status}
																								error={!!errors.status?.id}
																								helperText={errors?.status?.message && 'Status is required'}
																								FormHelperTextProps={{
																									style: {
																										margin: 0,
																										backgroundColor: '#f4f4f4',
																										width: '100%',
																										paddingTop: '2px',
																										fontSize: '10px',
																										color: '#FF0000'
																									}
																								}}
																								variant="outlined"
																								fullWidth
																							/>
																						</div>
																					)
																				}}
																			/>
																		</>
																	)}
																/>
															</FormControl>
														</Box>
													</Grid>
													<Grid item xs={6}>
														<Box>
															<Typography className='status_text'>Category <span className='required_span'>*</span></Typography>
															<FormControl fullWidth className='assets_listSelect'>
																<Controller
																	name="category"
																	className="mb-16"
																	control={control}
																	render={({ field: { ref, ...field }, fieldState: { error } }) => (
																		<>
																			<Autocomplete
																				{...field}
																				style={{ width: '100%' }}
																				className='main_autocomplete'
																				options={categoryMenu}
																				// disablePortal
																				PaperComponent={({ children }) => (
																					<Paper className='autocomplete_holder extra_styling'>{children}
																						<div className='p-16'>
																							<Button className='secondary_btn w-full' onMouseDown={() => handleOpenCloseAddCategory()}>
																								<i className='ti ti-plus' />
																								<span>Add Category</span>
																							</Button>
																						</div>
																					</Paper>
																				)}
																				onChange={(event, value) => field.onChange(value)}
																				getOptionLabel={option => option.label ?? ''}
																				renderInput={(params) => {
																					return (
																						<div ref={params.InputProps.ref}>
																							<TextField
																								name="category"
																								style={{ background: 'white' }}
																								{...params}
																								// label="Status"
																								placeholder='Select'
																								required
																								onChange={(event) => {
																									if (event && event.target) {
																										fetchCategorySuggestionsOnInputChange(event.target.value)
																									}
																								}}
																								defaultValue={defaultValues.category}
																								error={!!errors.category?.id}
																								helperText={errors?.category?.message && 'Category is required'}
																								FormHelperTextProps={{
																									style: {
																										margin: 0,
																										backgroundColor: '#f4f4f4',
																										width: '100%',
																										paddingTop: '2px',
																										fontSize: '10px',
																										color: '#FF0000'
																									}
																								}}
																								variant="outlined"
																								fullWidth
																							/>
																						</div>
																					)
																				}}
																			/>
																		</>
																	)}
																/>
															</FormControl>
														</Box>
													</Grid>
												</Grid>
											</Box>
										</>
									}

									{activeStep === 3 &&
										<>
											<Box>
												<Grid container spacing={2}>
													<Grid item xs={12}>
														<Box>
															<Typography className='status_text'>Subject Line <span className='required_span'>*</span></Typography>
															<FormControl fullWidth className='assets_listSelect'>
																<Controller
																	name="subjectLine"
																	className="mb-16"
																	control={control}
																	render={({ field: { ref, ...field }, fieldState: { error } }) => (
																		<>
																			<TextField
																				{...field}
																				placeholder='e.g Access Point is offline'
																				variant='outlined'
																				name="subjectLine"
																				disabled={editFromState}
																				fullWidth
																				onChange={(event, value) => {
																					field.onChange(event.target.value)
																				}}
																				error={!!errors.subjectLine}
																				helperText={errors?.subjectLine?.message}
																				FormHelperTextProps={{
																					style: {
																						margin: 0,
																						backgroundColor: '#f4f4f4',
																						width: '100%',
																						paddingTop: '2px',
																						fontSize: '10px',
																						color: '#FF0000'
																					}
																				}}
																			/>
																		</>
																	)}
																/>
															</FormControl>
														</Box>
													</Grid>
													<Grid item xs={12}>
														<div style={{ marginBottom: '10px' }}>
															<Typography className='status_text'>Description <span className='required_span'>*</span></Typography>
															<FormControl fullWidth className='assets_listSelect'>
																<Controller
																	name="description"
																	className="mb-16"
																	control={control}
																	render={({ field: { ref, ...field }, fieldState: { error } }) => (
																		<>
																			<Editor
																				{...field}
																				name="description"
																				placeholderText= "Tell us a bit more about the issue that can help us in investigating this"
																				editorContent={description}
																				disabled={editFromState}
																				onEditorContentChange={(value) => {
																					setDescription(value)
																					field.onChange(value)
																				}
																				}
																				setAssetNoteText={setDescriptionText}
																				error={!!errors.description}
																				helperText={errors?.description?.message && 'description is required!'}
																				FormHelperTextProps={{
																					style: {
																						margin: 0,
																						backgroundColor: '#f4f4f4',
																						width: '100%',
																						paddingTop: '2px',
																						fontSize: '10px',
																						color: '#FF0000'
																					}
																				}}
																			/>

																		</>
																	)}
																/>
																{errors?.description && <span className='required_span  mt-2' 
																	style={ {
																		fontSize: '12px',
																		color: '#FF0000',
																	}}
																>Description is required!</span>}
															</FormControl>

														</div>
													</Grid>
													<Grid item xs={12}>
														{/* Image upload */}
														<div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
															<Typography className='status_text'>Upload Files</Typography>
															<div className='upload_imgBox flex-wrap relative'>

																<label htmlFor="upload-photo" style={{ cursor: editFromState  ? 'not-allowed': 'pointer', maxWidth: '200px' }}>
																	<span style={{ color: '#667085' }} className='icon_holder'>
																		<Icon>
																			photo_camera
																		</Icon>
																		<span className='file_info_content'><span>Upload files</span><br/>Supports only gif, png, jpg, jpeg, doc, docx, pdf, txt, xls, xlsx, csv, ppt, pptx  Max size: 2Mb (rec. 700x430px)</span>
																	</span>
																</label>
																<input type="file"   
																disabled={editFromState}                  
																onChange={(e) => handelChange(e)}
 																name="photo" id="upload-photo" />
															
															</div>
														</div>
														<div  style={{ marginLeft: '10px' }} className='w-full'>
														{loading && <CircularProgress />}

															{
															selectedFiles.map((file, key) => (
																<>
																<div className='singleAttachment mt-1' key={key}>
																	<span className='me-3 fs-6 fw-semibold text-truncate'
																	onClick={() => {
																		if (file.name == '' || file.file_name == 'No File Name') return;
																		const fileExtension = file.name ? file.name.split('.').pop().toLocaleLowerCase() : file.file_name.split('.').pop().toLocaleLowerCase()
																		const imageFileExtension = ['png','jpeg','jpg']
																		if(imageFileExtension.includes(fileExtension)){
																			setOpenFilePreviewLink(true)
																			setFilePreviewLink(editFromState ? file.file_link : URL.createObjectURL(file))
																			setfileName(file.name ?? file?.file_name)
																		}else{

																		downloadFile(editFromState ? file.file_link : URL.createObjectURL(file),file.name ?? file?.file_name)
																		}
																	}}
																	 style={{
																		width: '150px', display: 'inline-block' , cursor: 'pointer'
																	}}>{file?.name ?? file?.file_name} </span>
																	{!editFromState &&
																	<i className='ti ti-xbox-x' onClick={(e) => {
																		 removeFile(file)
																		}}>
																	</i>
																	}
																	
																</div >
																</>
															))
															}
														</div>
													</Grid>
													{/* <Grid item xs={12}>
														<div style={{ marginBottom: '10px' }}>
															<Button
																className={`${editFromState ? 'bg-secondary' : 'secondary_btn'}`}
																variant="contained"
																component="label"
																size="large"
																disabled={editFromState}
																style={{ borderRadius: '4px', width: '150px', marginTop: '30px' }}
															>
																<i className='ti ti-paperclip' />	
																<span className='ml-4'>Choose a File</span>
																
																<input
																	ref={uploadInput}
																	type="file"
																	hidden
																	onChange={(event) => onChangeImageUpload(event)}
																/>
															</Button>
															{uploadingFile && (<div className={classes.loader}><CircularProgress /></div>)}
															<p
																style={{ marginTop: '55px', padding: '10px', cursor: 'pointer' }}
																onClick={() => setOpenFilePreviewLink(true)}
															>{fileName}</p>
														</div>

													</Grid> */}
												</Grid>
											</Box>
										</>
									}
								</div>
								<div className='btn_holder'>
									{activeStep !== 0 &&
										<Button
											color="inherit"
											disabled={activeStep === 0}
											onClick={handleBack}
											className='back_btn'
										>
											Back
										</Button>
									}

									{activeStep !== steps.length - 1 &&
										<Button onClick={handleNext} className='next_btn'>
											Next
										</Button>
									}
									{/* {activeStep !== steps.length &&
                                        (completed[activeStep] ? (
                                            <Typography variant="caption" sx={{ display: 'inline-block' }}>
                                                Step {activeStep + 1} already completed
                                            </Typography>
                                        ) : (
                                            <Button onClick={handleComplete}>
                                                {completedSteps() === totalSteps() - 1
                                                ? 'Proceed'
                                                : 'Complete Step'}
                                            </Button>
                                        ))
                                    } */}
									{activeStep === steps.length - 1 &&
										<Button onClick={handleSubmit(onSubmit)} disabled={isLoading || uploadingFile} className='next_btn'  style={{cursor: (isLoading || uploadingFile) ? 'not-allowed' : 'pointer'}}>
											Proceed
										</Button>
									}
								</div>
							</>
							{/* )} */}
						</div>
					</Box>

				</div>
			</Drawer>

			{openAlerModal &&
				<AlertModal openAlerModal module="service ticket" action={editFromState ? 'updating' : 'adding'} handleCloseAlertModal={handleCloseAlertModal} handleAlertModal={handleAlertModal} />
			}

			{
				openFilePreviewLink && <FilePreviewModal
					open={openFilePreviewLink}
					setOpen={setOpenFilePreviewLink}
					filePreviewLink={filePreviewLink}
					fileName={fileName}
				/>
			}

			{
				openAddStatusModal &&
				<AddServiceStatusFromQuickAction
					handleOpenCloseAddModalEvent={handleOpenCloseAddStatus}
					openAddStatusModalStateData={openAddStatusModal}
					newDataAddedEvent={newStatusAddedEvent}
				/>
			}

			{
				openAddSourceModal &&
				<AddServiceSourceFromQuickAction
					handleOpenCloseAddModalEvent={handleOpenCloseAddSource}
					openAddSourceModalStateData={openAddSourceModal}
					newDataAddedEvent={newSourceAddedEvent}
				/>
			}

			{
				openAddCategoryModal &&
				<AddServiceCategoryFromQuickAction
					handleOpenCloseAddModalEvent={handleOpenCloseAddCategory}
					openAddCategoryModalStateData={openAddCategoryModal}
					newDataAddedEvent={newCategoryAddedEvent}
				/>
			}

			{
				openAddPriorityModal &&
				<AddServicePriorityFromQuickAction
					handleOpenCloseAddModalEvent={handleOpenCloseAddPriority}
					openAddPriorityModalStateData={openAddPriorityModal}
					newDataAddedEvent={newPriorityAddedEvent}
				/>
			}

			{
				openAddUserModal && <AddUserFromQuickAction
					handleOpenCloseAddModalEvent={handleOpenCloseAddUser}
					openAddStatusModalStateData={openAddUserModal}
					newDataAddedEvent={newUserAddedEvent}
					companyId={getValues()?.client?.id}
				/>
			}


			<Modal
				open={openImagePreview}
				className={classes.modal}
			>
				<div style={{
					height: 500,
					width: 500,
					backgroundColor: 'white',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					borderRadius: '13px',
					display: 'flex',
					flexDirection: 'column'
				}}>
					<IconButton
						style={{ left: '203px', bottom: '19px' }}
						onClick={() => {
							setOpenImagePreview(false)
						}}>
						<Icon>close</Icon>
					</IconButton>
					<img style={{ height: '400px', width: '400px' }} src={previewImageLink} alt='' ></img>
				</div>
			</Modal>

		</div >
	)
}
export default AddServiceRequestFormDialog

function FilePreviewModal({ open, setOpen, filePreviewLink, fileName }) {
	const classes = useStyles()
	const fileExtension = fileName.split('.').pop().toLocaleLowerCase()
	const imageFileExtension = ['png', 'jpeg', 'jpg']
	let isImageFile = false
	if (imageFileExtension.includes(fileExtension)) {
		isImageFile = true
	}

	console.log('filePreviewLink', filePreviewLink)
	console.log('filePreviewLink isImageFile', isImageFile)


	return (
		<Modal
			open={open}
			className={classes.modal}
		>
			<div style={{
				height: '60vh',
				width: '50vw',
				backgroundColor: 'white',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				borderRadius: '13px',
				flexDirection: 'column',
				position: 'relative',
				padding: '30px'
			}}>
				<IconButton
					style={{ position: 'absolute', right: '0px', top: '0px' }}
					onClick={() => {
						setOpen(false)
					}}>
					<Icon>close</Icon>
				</IconButton>
				{
					isImageFile
						?
						<img style={{ height: '400px', width: '100%' }} src={filePreviewLink || ''} alt='' />
						:
						<iframe style={{ height: '400px', width: '100%' }} src={`https://docs.google.com/viewer?url=${encodeURIComponent(filePreviewLink)}&embedded=true`} />
				}
			</div>
		</Modal>
	)
}